<template>
  <div>
    <v-layout wrap class="mainbgx" style="height: 100vh">
      <v-flex>
        <v-layout wrap justify-center>
          <v-flex xs12 align-self-center>
            <v-snackbar
              v-model="showSnackBar"
              color="black"
              right
              :timeout="timeout"
            >
              <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                  <span style="color: white">
                    {{ msg }}
                  </span>
                </v-flex>
                <v-flex text-right>
                  <v-btn
                    small
                    :ripple="false"
                    text
                    @click="showSnackBar = false"
                  >
                    <v-icon style="color: white">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-snackbar>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end>
          <v-flex xs7>
            <v-layout wrap justify-center>
              <v-flex xs7 align-self-center pt-16>
                <v-card>
                  <v-layout wrap justify-center>
                    <v-flex xs3 pt-5>
                      <v-img
                        height="60px"
                        contain
                        src="../../assets/Images/blacklogo.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs10 text-center pt-2 class="mainfont">
                      <span style="font-size: 22px">Find Your Account</span>
                    </v-flex>
                    <v-flex xs10 pt-6 v-if="!otpFieldVisible">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        label="Phone Number"
                        type="number"
                        class="inputPrice"
                        v-model="mobNumber"
                        hide-details
                        :rules="guestPhoneRules"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xs10 pt-6 v-if="otpFieldVisible">
                      <v-text-field
                        color="black"
                        outlined
                        background-color="white"
                        dense
                        label="OTP"
                        type="number"
                        class="inputPrice"
                        v-model="otp"
                        hide-details
                      >
                      </v-text-field>
                      <v-flex xs10 pt-6 v-if="otpFieldVisible">
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          label="Password"
                          type="password"
                          class="inputPrice"
                          v-model="password"
                          hide-details
                        >
                        </v-text-field>
                      </v-flex>
                      <v-flex xs10 pt-6 v-if="otpFieldVisible">
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          label="Confirm Password"
                          type="password"
                          class="inputPrice"
                          v-model="confirmPassword"
                          hide-details
                        >
                        </v-text-field>
                      </v-flex>
                    </v-flex>
                    <v-flex pt-6 xs10>
                      <v-btn
                        class="no-uppercase mainfont"
                        dark
                        block
                        color="#314755"
                        @click="submit"
                      >
                        <span>{{
                          otpFieldVisible ? "Submit" : "Get OTP"
                        }}</span>
                        <v-icon color="white">mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex
                      @click="$router.push('/')"
                      style="cursor: pointer"
                      pb-8
                      class="mainfont"
                      text-center
                      pt-3
                      xs10
                    >
                      <span>Already a user? click here to Login</span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  // import axios from "axios";

  data() {
    return {
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      emailId: null,
      mobNumber: null,
      showPassword: false,
      showPassword2: false,
      passwordsMatch: true,
      password: null,
      confirmpassword: null,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
  },

  watch: {
    confirmpassword(newConfirmPassword) {
      if (newConfirmPassword !== this.password) {
        this.passwordsMatch = false;
      } else if (newConfirmPassword === null || this.password === null) {
        this.passwordsMatch = true;
      } else {
        this.passwordsMatch = true;
      }
    },
  },
  computed: {
    emailIdRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    mobileNumberRules() {
      return [
        (v) => !!v || "Mobile number is required",
        (v) => /^\d+$/.test(v) || "Mobile number must contain only numbers",
        (v) => v.length === 10 || "Mobile number must be exactly 10 digits",
      ];
    },
  },

  methods: {
    submit() {
      if (!this.mobNumber) {
        this.msg = "Please enter phone number";
        this.showSnackBar = true;
      } else if (!/^\d{10}$/.test(this.mobNumber)) {
        this.msg = "Phone number must be 10 digits";
        this.showSnackBar = true;
      } else {
        if (this.otpFieldVisible) {
          if (this.validateInput()) {
            this.register();
          }
        } else {
          this.otpFieldVisible = true;
          this.sendMobileNumber();
        }
      }
    },
    validateInput() {
      if (!this.otp) {
        this.msg = "Please enter OTP";
        this.showSnackBar = true;
        return false;
      } else if (!this.password) {
        this.msg = "Please enter password";
        this.showSnackBar = true;
        return false;
      } else if (this.password !== this.confirmPassword) {
        this.msg = "Passwords do not match";
        this.showSnackBar = true;
        return false;
      }
      return true;
    },
    register() {
      const encryptedOtp = CryptoJS.AES.encrypt(
        this.otp,
        'film:"?>}|irgwwvfsn[!@#$%^&*()shooting'
      ).toString();
      const encryptedPassword = CryptoJS.AES.encrypt(
        this.password,
        "film*^@fs(&%eadirgwwvn[!@#$%^&*()shooting"
      ).toString();
      // const encryptedUserid = CryptoJS.AES.encrypt(
      //   this.userId,
      //   'film{}:">#)(&$#!rfswwvn[!@#$%^&*()shooting'
      // ).toString();
      axios({
        method: "POST",
        url: "/forgotpassword/verify-otp",
        data: {
          otp: encryptedOtp,
          password: encryptedPassword,
          userid: this.userId,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            setTimeout(() => {
              this.$router.push({ path: "/" });
            }, 3000);
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendMobileNumber() {
  axios({
    method: "POST",
    url: "/forgot/password",
    headers: {
      token: localStorage.getItem("token"),
    },
    data: {
      mobNumber: this.mobNumber,
    },
  })
    .then((response) => {
      if (response.data.status === true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.userId = response.data.data._id;
        this.otpFieldVisible = true; // Show OTP fields only on success
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.otpFieldVisible = false; // Ensure OTP fields remain hidden on error
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.error(err);
      this.otpFieldVisible = false; // Keep OTP fields hidden on request failure
    });
}
  },
};
</script>
<style>
.mainbgx {
  background-image: linear-gradient(269.6deg, #1cb5e0 -31.66%, #000046);
}

/* Apply a gradient background color to an element with class 'bgcustom' */
.bgcustom {
  background: linear-gradient(180deg, #314755 0%, #26a0da 100%);
  color: white;
  /* Set the text color */
}
.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
